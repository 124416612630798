<template>
  <div id="LiveGrouping">
    <template  v-if="$route.name == 'liveGrouping'">
      <div class="header">
        <div class="left">
          <!-- <p class="title">智能模板</p> -->
          <div
            class="newTimeButton"
            @click="newdata"
          >
            新建分组
          </div>
        </div>
        <div class="right">
          <el-input
              v-model="post.search"
              size="medium"
              placeholder="输入分组名称搜索"
              style="width: 218px"
          ></el-input>
        </div>
      </div>
      <div class="paginationBox">
        <pagination2 :option="post" url="/liveGroup/getGroupList" ref="childDialogBox" class="mt20 childDialogBox flex1"
          @complete="complete">
          <template v-slot:default="{ tableData }">
            <el-table
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="tableData"
              @sort-change="sortChange"
            >
              <el-table-column label="分组名称" prop="group_name" width="400"></el-table-column>
              <el-table-column label="直播数" prop="num" sortable="custom"></el-table-column>
              <el-table-column label="创建时间" prop="uname" width="180" sortable="custom">
                <template slot-scope="scope">
                    {{ scope.row.c_time| formatTimeStamp('yyyy-MM-dd hh:mm') }}
                  </template>
              </el-table-column>
              <el-table-column label="操作" align="center" min-width="180">
                <template slot-scope="{ row }">
                  <el-button type="text" @click="torank1(row)">
                    内容管理
                  </el-button>
                  <el-button type="text" @click="edit(row)">
                    编辑
                  </el-button>
                  <el-button type="text" @click="share(row)">
                    分享
                  </el-button>
                  <el-button type="text" @click="del(row)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </pagination2>
      </div>
      <!-- 推广 -->
      <share
        v-if="showShare"
        :selectrow="selectrow"
        :dialogstatus.sync="showShare"
      ></share>
      <CourseConfirm
        v-if="showAddLive"
        :title="addLive.group_id?'编辑分组':'新建分组'"
        :isShowBtn="true"
        @cancelFn="closeFn"
        @save="saveLive"
      >
        <div class="addLive">
          <el-form
            label-width="100px"
            label-position="left"
            @submit.native.prevent
          >
            <!--分组名称-->
            <el-form-item
              label="分组名称"
              prop="set_name"
            >
              <div class="el-input-wrap">
                <el-input
                  v-model="addLive.group_name"
                  type="text"
                  size="medium"
                  placeholder="请输入内容"
                  maxlength="50"
                  show-word-limit
                ></el-input>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </CourseConfirm>
    </template>
    <router-view></router-view>
  </div>
</template>

<script>
import bg from '@/mixin/background'
import CourseConfirm from '@/components/CourseConfirm'
import share from './components/share'
export default {
  name:'liveGrouping',
  mixins: [bg],
  data(){
    return {
      post:{
        search:'',
        sort:'',
      },
      iscomplete: false,
      tableList:[],
      showAddLive:false,
      addLive:{},
      // 分享
      showShare:false,
      selectrow: {},
    }
  },
  components: {
    CourseConfirm,
    share,
  },
  methods:{
    newdata(){
      this.showAddLive=true
    },
    closeFn(){
      this.addLive = {}
      this.showAddLive=false
    },
    saveLive(){
      this.$http({
        url: this.addLive.group_id?'/liveGroup/setGroup':'/liveGroup/addGroup',
        data: Object.assign(this.addLive,{
          name:this.addLive.group_name,
        }),
        callback: () => {
          this.closeFn()
          this.$root.prompt({
            msg: this.addLive.group_id?'编辑成功':'新建成功',
            type: 'success',
          })
          this.$refs.childDialogBox.reset()
        },
        error: ({ info }) => {
          this.$root.prompt({
            msg: info,
          })
        },
      })
    },
    sortChange(row) {
      console.log(row);
      let order = null
      let sort = null
      switch (row.order) {
        case 'descending':
          sort='DESC'
          break
        case 'ascending':
          sort='ASC'
          break
      }
      switch (row.prop) {
        case 'num':
          order='num'
          break;
        case 'uname':
          order='c_time'
          break;
      }
      this.post.order = order
      this.post.sort = sort
    },
    edit(row){
      this.addLive = _.cloneDeep(row)
      this.showAddLive=true
    },
    share(row){
      this.selectrow = row
      this.$nextTick(() => {
        this.showShare = true
      })
    },
    // 删除
    async del(row) {
      this.$confirm('是否确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/liveGroup/delGroup',
            data: { group_id: row.group_id },
            successMsg: true,
          })
          this.$refs.childDialogBox.reset()
        })
        .catch(() => {})
    },
    // 获取列表数据
    complete(val) {
      this.tableList = val.data
      this.iscomplete = true
    },
    // 内容管理跳转
    torank1(row) {
      this.$router.push({
        path: `/liveGrouping/nextRank?group_id=${row.group_id}&group_name=${row.group_name}`,
      })
    },
  },
}
</script>

<style scoped lang="scss">
#LiveGrouping {
  margin: 20px;
  padding: 20px;
  background: #fff;
  min-width: 868px;
  .el-input-wrap {
    width: 518px;
  }
  .header {
    font-size: 13px;
    // padding: 20px 20px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      .title {
        font-size: 18px;
        margin-bottom: 20px;
      }
      .newTimeButton {
        width: 100px;
        height: 36px;
        color: #ffffff;
        background: rgba(10, 162, 155, 1);
        border-radius: 4px;
        cursor: pointer;
        text-align: center;
        border: 1px solid #0aa29b;
        font-size: 14px;
        line-height: 36px;
      }
    }
  }
  .addLive {
    width: 640px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 20px 82px;
    ::v-deep .el-input__inner {
      padding-right: 50px;
    }
  }
}
</style>